<style lang="less" scoped>
  @import "../../../assets/css/variables";

  .el-sel-width {
    width: 100%;
  }

  .list-table {
    .dvcimg {
      width: 100px;
      /*height: 100px;*/
    }
    .productImg {
      display: inline-block;
      width: 95px;
      height: 120px;
      padding: 0 10px;
      border: 1px solid #efefef;
      margin: 10px 0;
      text-align: center;
      line-height: 120px;
      color: #999;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      .img {
        width: 75px;
        height: 120px;
        margin: 0 auto;
        color: #bbb;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
      }
    }
  }
</style>

<template :is="view" transition="fade" transition-mode="out-in">
  <div class="page-wrapper">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/device/list' }">
        设备管理
      </el-breadcrumb-item>
      <el-breadcrumb-item>品牌型号列表</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="page-container clearfix" v-on:keyup.13="queryPublicTypes">
      <el-col :span="24" style="padding-bottom: 0px;">
        <el-form :inline="true" :model="filters">
          <el-form-item>
            <el-input
              style="width: 364px"
              v-model="filters.key"
              placeholder="请输入品牌名称/型号"
            ></el-input>
          </el-form-item>
          <el-form-item v-show="false">
            <el-input style="width: 200px"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="queryPublicTypes">搜索</el-button>
          </el-form-item>
        </el-form>
      </el-col>

      <el-table
        :data="brandList"
        v-loading.body="listLoading"
        @selection-change="selsChange"
        class="list-table"
      >
        <el-table-column type="selection" width="35"></el-table-column>

        <el-table-column label="品牌图片" width="140" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.images" class="productImg">
              <div
                :key="scope.row.images"
                class="img"
                v-lazy:background-image="API_ROOT + scope.row.images"
              ></div>
            </div>
            <div v-if="!scope.row.images" class="productImg">暂无图片</div>
          </template>
        </el-table-column>

        <el-table-column
          :show-overflow-tooltip="true"
          prop="customerName"
          label="品牌商"
        ></el-table-column>

        <el-table-column :show-overflow-tooltip="true" label="品牌型号">
          <template slot-scope="scope">
            {{ scope.row.belongsType + scope.row.belongsSeries }}
          </template>
        </el-table-column>

        <el-table-column
          :show-overflow-tooltip="true"
          prop="deviceType"
          label="产品型号"
        >
          <template slot-scope="scope">
            {{ scope.row.deviceTypeName }}
          </template>
        </el-table-column>

        <el-table-column
          :show-overflow-tooltip="true"
          prop="remarks"
          label="备注信息"
        ></el-table-column>

        <el-table-column label="创建时间">
          <template slot-scope="scope">
            <span v-if="scope.row.createTime">
              {{ $moment(scope.row.createTime).format("YYYY-MM-DD HH:mm:ss") }}
            </span>
          </template>
        </el-table-column>

        <el-table-column width="160" label="操作">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click="$router.push('/device/brand/detail/' + scope.row.id)"
            >
              修改
            </el-button>

            <el-button
              type="danger"
              size="small"
              @click="handleDelete(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <!--工具条-->
      <el-col :span="24" class="bottom-tool-container">
        <el-button type="primary" @click="$router.push('/device/brand/detail')">
          新增品牌
        </el-button>

        <el-button
          type="danger"
          :disabled="this.sels.length === 0"
          @click="handleDelete"
        >
          批量删除
        </el-button>

        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :total="total"
          :page-size="+pager.page || 0"
          :current-page="+pager.page || 0"
          :page-sizes="[PAGE_SIZE, PAGE_SIZE * 2, PAGE_SIZE * 3, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          class="pull-right"
        ></el-pagination>
      </el-col>
    </div>
  </div>
</template>

<script>
  import { PAGE_SIZE, API_ROOT } from "src/config";
  import { mapGetters, mapActions } from "vuex";
  import { searchProByCustomer, delbrandType } from "src/services/device";

  export default {
    data() {
      return {
        API_ROOT,
        PAGE_SIZE,
        listLoading: false,
        total: 1,
        sels: [], //列表选中列
        brandList: [],
        pager: {
          size: PAGE_SIZE,
          page: 1,
          sort: "updateTime,desc",
        },
        filters: {
          key: "",
          customer: null,
        },
      };
    },
    computed: {
      ...mapGetters({
        user: "userInfo",
      }),
    },
    methods: {
      // checkbox勾选的变动的回调
      selsChange(sels) {
        this.sels = sels;
      },

      // 翻页
      handleCurrentChange(page) {
        this.pager.page = page;
        this.queryPublicTypes();
      },

      // 设置分页的size
      handleSizeChange(val) {
        this.pager.size = val;
        this.pager.page = 1;
        this.queryPublicTypes();
      },

      // 删除和批量删除
      handleDelete(row) {
        let delIds = "";
        if (row && row.id) {
          delIds = row.id;
        } else {
          delIds = this.sels.map((item) => item.id).toString();
        }

        this.$confirm("确认删除选中记录吗？", "提示", {
          type: "warning",
        })
          .then(() => {
            this.listLoading = true;
            delbrandType(delIds).then((res) => {
              this.listLoading = false;
              if (res.errorCode === 0) {
                this.$notify.success({
                  title: "成功",
                  message: "删除成功",
                });
                this.queryPublicTypes();
              }
            });
          })
          .catch(() => {});
      },

      // 获取品牌列表
      async queryPublicTypes() {
        this.listLoading = true;
        const resData = await searchProByCustomer(this.filters, this.pager);

        if (resData.errorCode === 0 && resData.data) {
          this.brandList = resData.data.list || [];
          this.total = resData.data.total || 0;
        }

        this.listLoading = false;
      },
    },
    created() {
      this.queryPublicTypes();
    },
  };
</script>
